import "./vendors.js";
import "./css/index.css";

import lottie from 'lottie-web';
import readingAssignment from './assets/Json animations/Reading Assignment.json';
import Math1 from './assets/Json animations/Mathematics.json';
import Math2 from './assets/Json animations/Choosing Major.json';


function logosSlide() {
    var logo = document.querySelector(".logos");
var copy = document.querySelector(".logos-slide").cloneNode(true);
console.log(copy);
console.log(logo);
logo.appendChild(copy);
}

// when the DOM is ready

// document.addEventListener('DOMContentLoaded', function () {
//   console.log('Pathname:', window.location.pathname);

//   const videoModal = document.getElementById('videoModal');
//   const videoPlayer = document.getElementById('videoPlayer');

//   videoModal.addEventListener('hidden.bs.modal', () => {
//     videoPlayer.pause(); // Pause the video when the modal is closed
//     videoPlayer.currentTime = 0; // Optionally reset the video to the start
//   });
// // Load the animation with `animationData`
// lottie.loadAnimation({
//   container: document.getElementById('animation-container'), // the container for the animation
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   animationData: readingAssignment // Use `animationData` instead of `path`
// });
//   lottie.loadAnimation({
//   container: document.getElementById('Math1'), // the container for the animation
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   animationData: Math1 // Use `animationData` instead of `path`
// });
//   lottie.loadAnimation({
//   container: document.getElementById('Math2'), // the container for the animation
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   animationData: Math2 // Use `animationData` instead of `path`
// });
//     logosSlide();

//   if (window.location.pathname === '/CareerPath.html') {
//     console.log('CareerPath.html');
//     const getStarted = document.querySelector('#StartNow');
//     const background = document.querySelector('.background');
//     const footerCarer = document.querySelector('.footerCarer');
//     const CareerPath = document.querySelector('.CareerPath');
//     const form = document.querySelector('form');
//     const CareerResults = document.querySelector('#CareerResults');
//     const indexButton = document.querySelector('#indexButton');

//     // add event lisner to getStarted button
//     getStarted.addEventListener('click', function () {
//       background.style.display = 'none';
//       footerCarer.style.display = 'none';
//       CareerPath.style.display = 'none';
//       form.style.display = 'block';
//     });
    
//     // Handle form submission
//     form.addEventListener('submit', function (event) {
//       event.preventDefault(); // Prevent default form submission
  
//       // Simple validation to check if all required fields are filled
//       if (form.checkValidity()) {
//         // If form is valid, show the career path results
//         form.style.display = 'none';
//         careerPathSection.style.display = 'block';
//         // Handle index button click

//       }
//     });
//   }

// });

document.addEventListener('DOMContentLoaded', function () {
  console.log('Pathname:', window.location.pathname);


  

  // Lottie animations
  lottie.loadAnimation({
      container: document.getElementById('animation-container'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: readingAssignment,
  });

  lottie.loadAnimation({
      container: document.getElementById('Math1'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Math1,
  });

  lottie.loadAnimation({
      container: document.getElementById('Math2'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Math2,
  });

  // Logos slide function
  if (window.location.pathname.includes('index.html')) {
    logosSlide();
    const videoModal = document.getElementById('videoModal');
const videoPlayer = document.getElementById('videoPlayer');

const playVideo = () => {
    videoPlayer.play();
};

const pauseVideo = () => {
    videoPlayer.pause();
    videoPlayer.currentTime = 0; // Reset to the start
};

// Attach event listeners
videoModal.addEventListener('shown.bs.modal', playVideo);
videoModal.addEventListener('hidden.bs.modal', pauseVideo);

// Clean up event listeners when navigating away
window.addEventListener('beforeunload', () => {
    videoModal.removeEventListener('shown.bs.modal', playVideo);
    videoModal.removeEventListener('hidden.bs.modal', pauseVideo);
});
    
window.addEventListener('beforeunload', () => {
  if (videoModal.classList.contains('show')) {
      const modalInstance = bootstrap.Modal.getInstance(videoModal);
      modalInstance.hide();
  }
});

}
  // Check for CareerPath page
  if (window.location.pathname.includes('CareerPath.html')) {
      console.log('CareerPath.html');
      const getStarted = document.querySelector('#StartNow');
      const background = document.querySelector('.background');
      const CareerPath = document.querySelector('#CareerPath');
      const form = document.querySelector('form');
      const CareerResults = document.querySelector('#CareerResults');
    const FindMyCareer = document.querySelector('#FindMyCareer');
    const payment = document.querySelector('.payment');
    const paymentButton = document.querySelector('#show-details-btn');

      if (getStarted) {
          getStarted.addEventListener('click', function () {
              if (background) background.style.display = 'none';
            if (CareerPath) CareerPath.style.display = 'block';
            console.log(CareerPath);
          });
      }

    if (FindMyCareer) {
      FindMyCareer.addEventListener('click', function (e) {
        if (form.checkValidity()) {
          e.preventDefault();
          form.style.display = 'none';
          CareerResults.style.display = 'block';
        }
      });
    };

    if (paymentButton) {
      paymentButton.addEventListener('click', function () {
        CareerResults.style.display = 'none';
        payment.style.display = 'block';
      });
    }

    const selectableCards = document.querySelectorAll(".selectable-card");

        selectableCards.forEach(card => {
            card.addEventListener("click", () => {
                card.classList.toggle("active"); // Toggle active state
                const selectedValues = Array.from(
                    document.querySelectorAll(".selectable-card.active")
                ).map(card => card.dataset.value);

                console.log("Selected values:", selectedValues); // You can use this list for form submission
            });
        });
    }
  
  
});

// Function to reset the form and hide the results section
function restartForm() {
    const form = document.querySelector('form');
    form.reset(); // Reset all form fields
    form.classList.remove('was-validated'); // Remove validation styles
    document.getElementById('CareerResults').style.display = 'none'; // Hide results section
  }
  
  // Function to redirect to the index page
  function goToIndex() {
    window.location.href = 'index.html'; // Redirect to the index page
  }
  